.Container {
  background-color: rgba(250, 250, 250, 0.75);
  padding: 10px;
  height: 200px;
  border: 1px solid rgba(225, 225, 225, 1);
  border-radius: 8px;
  border-style: dashed;
  border-width: 3px;
  transition: all 0.25s ease 0s;
  position: relative;
}
.Container:hover {
  background-color: rgba(33, 150, 243, 0.15);
  border-color: rgba(33, 150, 243, 1);
  transition: all 0.25s ease 0s;
}

.Container.highlight {
  background-color: rgba(33, 150, 243, 0.15);
  border-color: rgba(33, 150, 243, 1);
  transition: all 0.25s ease 0s;
}

.ChooseButton {
  margin-top: 25px;
}

.AlertIcon{
  position: relative;
  top: 3px;
  font-size: 1.5rem;
}

.AlertIcon:hover{
  fill: rgba(33, 150, 243, 1);
  transition: all 0.2s ease 0s;
  cursor: pointer;
}


/* style={{border: "1px solid #dcdcdc", width: "250px", marginTop: "11px"}} */

.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dcdcdc;
  margin-top: 11px;
}

.search-bar .MuiInputBase-root {
  width: 100%;
}

input[type="file"] {
  background-color: red;
}

input[type="button"] {
  background-color: red;
}

input[type="submit"] {
  background-color: red;
}

input {
  background-color: green;
}

.choose-file-container {
  /* width: 100%; */
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.choose-file {
  position: absolute;
  visibility: hidden;
}

.no-shadow {
  box-shadow: none !important;
}

.button-padding {
  padding: 6px 36px !important;
}