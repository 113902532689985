/* .recharts-cartesian-axis-tick {
  font-size: 2.5rem;
  font-family: Roboto, sans-serif;
} */

.recharts-text recharts-label {
  font-family: "Roboto";
  font-size: 2.0rem !important;
  text-anchor: middle;
}

/* svg.recharts-surface tspan {
  font-size: 0.8rem !important;
  color: black !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
} */

.recharts-cartesian-axis{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.recharts-label{
    font-weight: 500;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase
}