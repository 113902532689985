.employee-change {
  position: relative;
}

.employee-change-loading {
  position: absolute;
  top: 30px;
  right: 41px;
}
/* 
.recharts-legend-wrapper {
  position: relative !important;
} */

.recharts-default-legend {
  display: flex;
  flex-direction: column;
}

.date-button-container {
  display: flex;
  margin-top: 8px;
}

.search-button-container {
  margin-top: 16px;
}

.no-shadow {
  box-shadow: none !important;
}

.button-padding {
  padding: 6px 36px !important;
}
