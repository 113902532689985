.native-select {
  width: 100%;
  height: 40px;
  background-color: #f3f3f3;
  border-radius: 4px;
  border: 1px solid #bbbbbb;
  padding-left: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 15px;
}

.arrow-down-icon {
  position: absolute;
  right: 25px;
  top: 9px;
  color: #6f6f6f;
}

.MuiExpansionPanelDetails-root {
  overflow-x: auto !important;
}

.no-shadow {
  box-shadow: none !important;
}

.button-padding {
  padding: 6px 36px !important;
}
