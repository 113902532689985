/* .panel {
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;
  justify-content: center;
  align-items: center;
}

.video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
} */

.logo {
  width: 125px;
  transform: translateX(-4px);
  margin-bottom: 8px;
}

.header4 {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
}
.no-shadow {
  box-shadow: none !important;
}

.button-padding {
  padding: 6px 36px !important;
}

